var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "borrow" }, [
    _c("div", { staticClass: "banner" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "reach-show",
              rawName: "v-reach-show",
              value: "elegant-in",
              expression: "'elegant-in'",
            },
          ],
          staticClass: "banner-content container",
        },
        [
          _c("h1", { staticClass: "banner-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("borrow.banner.title")) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "banner-desc" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("borrow.banner.text")) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _vm.$i18n.locale !== "es"
            ? _c(
                "button",
                { staticClass: "google-play", on: { click: _vm.download } },
                [
                  _c("img", {
                    attrs: { src: require("./img/google-play.png"), alt: "" },
                  }),
                ]
              )
            : _c("img", {
                staticClass: "google-play es",
                attrs: { src: require("./img/google-play-es.svg"), alt: "" },
                on: { click: _vm.download },
              }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "features" }, [
      _c("h2", [_vm._v(_vm._s(_vm.$t("borrow.features.title")))]),
      _vm._v(" "),
      _c("div", { staticClass: "features-content container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "reach-show",
                rawName: "v-reach-show",
                value: "elegant-in",
                expression: "'elegant-in'",
              },
            ],
            staticClass: "feature",
          },
          [
            _c("img", {
              attrs: { src: require("./img/features-1.png"), alt: "" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("borrow.features.cards[0].title"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("borrow.features.cards[0].text")) +
                    "\n                    "
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "reach-show",
                rawName: "v-reach-show",
                value: "elegant-in",
                expression: "'elegant-in'",
              },
            ],
            staticClass: "feature",
          },
          [
            _c("img", {
              attrs: { src: require("./img/features-2.png"), alt: "" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("borrow.features.cards[1].title"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("borrow.features.cards[1].text")) +
                    "\n                    "
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "reach-show",
                rawName: "v-reach-show",
                value: "elegant-in",
                expression: "'elegant-in'",
              },
            ],
            staticClass: "feature",
          },
          [
            _c("img", {
              attrs: { src: require("./img/features-3.png"), alt: "" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("borrow.features.cards[2].title"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("borrow.features.cards[2].text")) +
                    "\n                    "
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "reach-show",
                rawName: "v-reach-show",
                value: "elegant-in",
                expression: "'elegant-in'",
              },
            ],
            staticClass: "feature",
          },
          [
            _c("img", {
              attrs: { src: require("./img/features-4.png"), alt: "" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("borrow.features.cards[3].title"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "desc" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("borrow.features.cards[3].text")) +
                    "\n                    "
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "reach-show",
            rawName: "v-reach-show",
            value: "elegant-in",
            expression: "'elegant-in'",
          },
        ],
        staticClass: "process",
      },
      [
        _c("div", { staticClass: "process-content container" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("borrow.process.title")))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "step-content row" },
            _vm._l(_vm.steps, function (step) {
              return _c(
                "div",
                { key: "step-" + step, staticClass: "step sub-body col-md-6" },
                [
                  _c("h6", [
                    _vm._v(
                      _vm._s(
                        "0" +
                          (step + 1) +
                          " " +
                          _vm.$t("borrow.process.items[" + step + "].title")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "step-content" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t("borrow.process.items[" + step + "].text")
                        ) +
                        "\n                    "
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }