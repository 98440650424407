<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-04-30 18:03:57.490 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <div class="borrow">
        <div class="banner">
            <div
                v-reach-show="'elegant-in'"
                class="banner-content container"
            >
                <h1 class="banner-title">
                    {{ $t('borrow.banner.title') }}
                </h1>
                <div class="banner-desc">
                    {{ $t('borrow.banner.text') }}
                </div>
                <button
                    v-if="$i18n.locale !== 'es'"
                    class="google-play"
                    @click="download"
                >
                    <img
                        src="./img/google-play.png"
                        alt=""
                    >
                </button>
                <img
                    v-else
                    src="./img/google-play-es.svg"
                    alt=""
                    class="google-play es"
                    @click="download"
                >
            </div>
        </div>

        <div class="features">
            <h2>{{ $t('borrow.features.title') }}</h2>
            <div class="features-content container">
                <div
                    v-reach-show="'elegant-in'"
                    class="feature"
                >
                    <img
                        src="./img/features-1.png"
                        alt=""
                    >
                    <div class="text">
                        <h3>{{ $t(`borrow.features.cards[0].title`) }}</h3>
                        <div class="desc">
                            {{ $t(`borrow.features.cards[0].text`) }}
                        </div>
                    </div>
                </div>
                <div
                    v-reach-show="'elegant-in'"
                    class="feature"
                >
                    <img
                        src="./img/features-2.png"
                        alt=""
                    >
                    <div class="text">
                        <h3>{{ $t(`borrow.features.cards[1].title`) }}</h3>
                        <div class="desc">
                            {{ $t(`borrow.features.cards[1].text`) }}
                        </div>
                    </div>
                </div>
                <div
                    v-reach-show="'elegant-in'"
                    class="feature"
                >
                    <img
                        src="./img/features-3.png"
                        alt=""
                    >
                    <div class="text">
                        <h3>{{ $t(`borrow.features.cards[2].title`) }}</h3>
                        <div class="desc">
                            {{ $t(`borrow.features.cards[2].text`) }}
                        </div>
                    </div>
                </div>
                <div
                    v-reach-show="'elegant-in'"
                    class="feature"
                >
                    <img
                        src="./img/features-4.png"
                        alt=""
                    >
                    <div class="text">
                        <h3>{{ $t(`borrow.features.cards[3].title`) }}</h3>
                        <div class="desc">
                            {{ $t(`borrow.features.cards[3].text`) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-reach-show="'elegant-in'"
            class="process"
        >
            <div class="process-content container">
                <h2>{{ $t('borrow.process.title') }}</h2>

                <div class="step-content row">
                    <div
                        v-for="step in steps"
                        :key="`step-${step}`"
                        class="step sub-body col-md-6"
                    >
                        <h6>{{ `0${step + 1} ${$t(`borrow.process.items[${step}].title`)}` }}</h6>
                        <div class="step-content">
                            {{ $t(`borrow.process.items[${step}].text`) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import {downloadUrl} from 'olacred/common/constant/config';

export default {
    name: 'Borrow',

    data() {
        return {
            steps: [0, 1, 2, 3]
        };
    },

    methods: {
        download() {
            window.open(downloadUrl);
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "~olacred/common/style/olacred";

.borrow {
    overflow-x: hidden;

    button {
        cursor: pointer;
    }

    .container {
        padding: 0;
    }

    .banner {
        background-color: #fff;
        background-image: url("./img/banner.png");
        background-size: contain;
        background-position: right bottom;
        background-repeat: no-repeat;
        padding: 210px 0 180px;

        .banner-img {
            width: 100%;
        }

        .banner-title {
            max-width: 510px;
            font-size: 50px;
            font-family: Roboto-Bold, Roboto;
            font-weight: 900;
            color: #333;
            line-height: 75px;
        }

        .banner-desc {
            margin-top: 20px;
            max-width: 510px;
            font-size: 16px;
            font-family: Helvetica;
            color: #666;
            line-height: 22px;
        }

        .google-play {
            margin-top: 77px;
            background: #333;
            border-radius: 36px;
            padding: 15px 53px;

            &:focus {
                outline: none;
            }

            img {
                width: 134px;
            }

            &.es {
                padding: 0;
                width: 240px;
            }
        }

        @media screen and (max-width: $screen-md) {
            padding: 3.2rem 30px 50px;
            background-image: url("./img/banner-mobile.png");
            background-position: right top;

            .banner-title {
                font-size: 28px;
                line-height: 38px;
            }

            .banner-desc {
                margin-top: 20px;
                font-size: 12px;
            }

            .google-play {
                margin-top: 0.2rem;
                padding: 10px 40px;

                img {
                    width: 100px;
                }

                &.es {
                    padding: 0;
                    width: 180px;
                }
            }
        }
    }

    .features {
        padding: 88px 0 148px;
        background: #f5f6fb;

        h2 {
            text-align: center;
            margin-bottom: 111px;
            color: #333;
        }

        .features-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @media screen and (max-width: $screen-lg) {
                justify-content: center;
            }
        }

        .feature {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-shrink: 0;
            margin-top: 40px;
            padding: 0 40px;
            height: 195px;
            transition: all 0.5s ease-in-out;

            &:hover {
                background: #fff;
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 15%);
                border-radius: 12px;
            }

            img {
                width: 80px;
            }

            .text {
                margin: 12px 0 0 36px;
                max-width: 280px;

                h3 {
                    font-size: 20px;
                    font-family: Roboto-Bold, Roboto;
                    font-weight: bold;
                    color: #333;
                    line-height: 27px;
                }

                .desc {
                    margin-top: 12px;
                    font-size: 16px;
                    font-family: Roboto-Light, Roboto;
                    font-weight: 300;
                    color: #666;
                    line-height: 21px;
                }
            }
        }

        @media screen and (max-width: $screen-md) {
            padding: 62px 0;

            h2 {
                margin-bottom: 0;
            }

            .feature {
                margin-top: 10px;
                padding: 10px 30px;

                &:hover {
                    background: #fff;
                }

                & > img {
                    width: 60px;
                }

                .text {
                    max-width: 200px;

                    h3 {
                        font-size: 16px;
                    }

                    .desc {
                        font-size: 12px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    .process {
        padding: 80px 0 50px;
        background: url("./img/process-bg.png") center/cover no-repeat;

        h2 {
            text-align: center;
            color: #fff;
        }

        .step-content {
            .step {
                margin-top: 40px;

                h6 {
                    color: #fff;
                }

                &-content {
                    margin-top: 20px;
                    width: 70%;
                    color: #fff;
                }
            }
        }

        @media screen and (max-width: $screen-md) {
            padding: 62px 30px;

            .step-content {
                .step {
                    margin-top: 50px;

                    h6 {
                        font-size: 16px;
                    }

                    &-content {
                        margin-top: 12px;
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
